var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list_container appliedList" },
    [
      _c(
        "div",
        { staticClass: "search-box" },
        [
          _c("i-header", {
            attrs: {
              title: _vm.config["title"] ? _vm.config["title"] : "",
              showBack: true,
            },
            on: { "back-event": _vm.toBack },
          }),
          _c("i-search", {
            attrs: {
              shape: "round",
              clearable: "",
              "clear-trigger": "always",
              placeholder:
                "请输入会议名称/" +
                (_vm.tenantCode === "dsm" ? "申请书" : "会议") +
                "编号" +
                (_vm.tenantCode === "crossevent" ? "/预算编号" : ""),
            },
            on: { input: _vm.onSearch },
            model: {
              value: _vm.searchKey,
              callback: function ($$v) {
                _vm.searchKey = $$v
              },
              expression: "searchKey",
            },
          }),
          _c(
            "van-tabs",
            {
              on: { change: _vm.tabChange },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c("van-tab", { attrs: { title: "待授权", name: "1" } }),
              _c("van-tab", { attrs: { title: "已同意", name: "0" } }),
              _c("van-tab", { attrs: { title: "已拒绝", name: "2" } }),
            ],
            1
          ),
        ],
        1
      ),
      _c("event-item-list", { ref: "eventlist" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }